import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import PressCarousel from '../PressCarousel'
import { CALENDAR_SOCIAL_PRESS_CONSTANTS, FRAME_PRESS_CONSTANTS } from './constants'

import { Section, Header } from './styles'

const PressTestimonials = ({ bgColor, page, top, bottom }) => {
  const { bump, truetrae, motherly, lifesavvy, today, cnet, techRadar, wired } =
    useStaticQuery(graphql`
      query PressTestimonialsQuery {
        bump: file(relativePath: { eq: "press/the-bump.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        truetrae: file(relativePath: { eq: "press/truetrae.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        motherly: file(relativePath: { eq: "press/motherly.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        lifesavvy: file(relativePath: { eq: "press/lifesavvy.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        today: file(relativePath: { eq: "redesign/today.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 160, placeholder: NONE)
          }
        }
        cnet: file(relativePath: { eq: "redesign/cnet.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, placeholder: NONE)
          }
        }
        techRadar: file(relativePath: { eq: "redesign/tech-radar.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 160, placeholder: NONE)
          }
        }
        wired: file(relativePath: { eq: "redesign/wired.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 160, placeholder: NONE)
          }
        }
      }
    `)

  const CALENDAR_META = {
    bump,
    truetrae,
    motherly,
    lifesavvy,
  }

  const FRAME_IMAGES = {
    today,
    cnet,
    techRadar,
    wired,
  }

  const TESTIMONIAL_OVERRIDE = {
    frame: FRAME_PRESS_CONSTANTS,
  }
  const IMAGE_OVERRIDE = {
    frame: FRAME_IMAGES,
  }
  const testimonials = TESTIMONIAL_OVERRIDE[page] || CALENDAR_SOCIAL_PRESS_CONSTANTS
  const images = IMAGE_OVERRIDE[page] || CALENDAR_META
  return (
    <Section bgColor={bgColor}>
      <Header>In the news</Header>
      <PressCarousel
        images={images}
        testimonials={testimonials}
        bgColor={bgColor}
        page={page}
        bottom={bottom}
        top={top}
      />
    </Section>
  )
}

PressTestimonials.propTypes = {
  page: PropTypes.string,
  bgColor: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
}
export default PressTestimonials
