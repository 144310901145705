import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import fitsYourSpace from 'src/gifs/fits-your-space-desktop.gif'
import Analytics from 'src/utils/Analytics'
import { Header, Center } from '../FramePress/styles'
import {
  CalMaxContainer,
  Container,
  Heading,
  Description,
  Card,
  CtaContainer,
  LogoContainer,
  TextContainer,
  CardLink,
  BuyNowLinkCTA,
  PressContainer,
  PressIcon,
  Flexbox,
} from './styles'
import { BUY_LINK, CAL_BUY_LINK } from '../../utils/constants'

const HomepageProducts = () => {
  const { frameProduct, calendarProduct, zdnet, verge, techradar } = useStaticQuery(graphql`
    query HomepageProductsQuery {
      frameProduct: file(relativePath: { eq: "homepage/colored-frames.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendarProduct: file(relativePath: { eq: "calendar/calendar-details.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      zdnet: file(relativePath: { eq: "zdnet.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      verge: file(relativePath: { eq: "verge.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      techradar: file(relativePath: { eq: "techradar.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const productDetails = [
    {
      image: frameProduct,
      alt: 'Skylight Frame with a picture of a baby in grass',
      link: BUY_LINK,
      name: 'Skylight Frame',
      text: 'Share photos and videos with loved ones anytime, from anywhere.',
      ctaText: 'Shop Frame',
      analytics: 'Clicked Shop Frame in Features CTA',
    },
    {
      image: calendarProduct,
      alt: 'Skylight Calendar on schedule display view',
      link: CAL_BUY_LINK,
      name: 'Skylight Calendar',
      text: `Display all of your calendars on one simple, touchscreen device.`,
      ctaText: 'Shop Calendar',
      analytics: 'Clicked Shop Calendar in Features CTA',
    },
  ]
  return (
    <>
      <CalMaxContainer>
        <Flexbox>
          <Heading>Introducing Calendar Max ✨</Heading>
          <Description>
            Simplify your busy household and experience all the beloved features of our 10" and 15"
            Calendars unified in one expansive, high-resolution display, tailor-made for dynamic
            family life.
          </Description>
          <CtaContainer>
            <Link
              onClick={() => Analytics.track('Clicked Preorder Cal Max in Features CTA')}
              to="/products/skylight-calendar-max/"
            >
              <BuyNowLinkCTA>Preorder Cal Max</BuyNowLinkCTA>
            </Link>
          </CtaContainer>
        </Flexbox>
        <img src={fitsYourSpace} alt="Skylight Calendar turns into a screensaver" width="100%" />
      </CalMaxContainer>
      <Center>
        <Header>Featured In</Header>
      </Center>
      <PressContainer>
        <a href="https://www.techradar.com/computing/monitors/this-giant-27-inch-digital-calendar-is-here-to-organize-your-familys-life">
          <PressIcon id="techradar">
            <GatsbyImage image={techradar.childImageSharp.gatsbyImageData} alt="Techradar logo" />
          </PressIcon>
        </a>
        <a href="https://www.zdnet.com/home-and-office/smart-home/this-27-inch-smart-display-could-be-the-giant-digital-family-calendar-youve-been-waiting-for/">
          <PressIcon>
            <GatsbyImage image={zdnet.childImageSharp.gatsbyImageData} alt="ZDnet logo" />
          </PressIcon>
        </a>
        <a href="https://www.theverge.com/2024/1/30/24054438/skylight-cal-max-smart-family-calendar-launch-pricing">
          <PressIcon id="verge">
            <GatsbyImage image={verge.childImageSharp.gatsbyImageData} alt="Verge logo" />
          </PressIcon>
        </a>
      </PressContainer>
      <Container>
        {productDetails.map((product, i) => (
          <CardLink onClick={() => Analytics.track(product.analytics)} to={product.link} key={i}>
            <Card>
              <LogoContainer id={i} calendar={i === 1}>
                <GatsbyImage
                  image={product.image.childImageSharp.gatsbyImageData}
                  alt={product.alt}
                />
              </LogoContainer>
              <TextContainer>
                <Heading>{product.name}</Heading>
                <Description>{product.text}</Description>
                <CtaContainer>
                  <BuyNowLinkCTA>{product.ctaText}</BuyNowLinkCTA>
                </CtaContainer>
              </TextContainer>
            </Card>
          </CardLink>
        ))}
      </Container>
    </>
  )
}

export default HomepageProducts
