/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Slider from 'react-slick'
import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import { PRODUCT_DETAILS } from './constants'
import { BuyNowLink } from '../Hero/styles'

import {
  Container,
  Header,
  Subheading,
  TextBox,
  Row,
  TextContainer,
  TextPosition,
  HeroVideoContainer,
  CtaContainer,
  HeroMobileImageContainer,
} from './styles'

export default function HomepageHero() {
  const { frameBackground, calBackground, frameBackgroundMobile, calBackgroundMoible } =
    useStaticQuery(graphql`
      query HomepageHeroQuery {
        frameBackground: file(relativePath: { eq: "homepage/background-frame.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        calBackground: file(relativePath: { eq: "calendar/cal-uhp-desktop-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        frameBackgroundMobile: file(relativePath: { eq: "homepage/background-frame-mobile.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        calBackgroundMoible: file(relativePath: { eq: "calendar/cal-uhp-mobile-hero.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          adaptiveHeight: true,
          arrows: false,
          lazyLoad: false,
        },
      },
    ],
  }
  return (
    <Container>
      <Slider {...settings}>
        {PRODUCT_DETAILS.map((slide, i) => (
          <Row key={i}>
            <HeroVideoContainer>
              <GatsbyImage
                image={
                  slide.productId === '7073751498804'
                    ? frameBackground.childImageSharp.gatsbyImageData
                    : calBackground.childImageSharp.gatsbyImageData
                }
                alt="Skylight Frame and Skylight Calendar"
              />
            </HeroVideoContainer>
            <HeroMobileImageContainer>
              <GatsbyImage
                image={
                  slide.productId === '7073751498804'
                    ? frameBackgroundMobile.childImageSharp.gatsbyImageData
                    : calBackgroundMoible.childImageSharp.gatsbyImageData
                }
                alt="Skylight Frame and Skylight Calendar"
              />
            </HeroMobileImageContainer>
            <TextPosition id="text">
              <TextContainer>
                <YotpoStars color={theme.orange} textColor="#444444" productId={slide.productId} />
                <TextBox>
                  <Header>{slide.heading}</Header>
                  <Subheading>{slide.subheading}</Subheading>
                </TextBox>
                <CtaContainer>
                  <BuyNowLink onClick={() => Analytics.track(slide.analyticsEvent)} to={slide.link}>
                    {slide.ctaText}
                  </BuyNowLink>
                </CtaContainer>
              </TextContainer>
            </TextPosition>
          </Row>
        ))}
      </Slider>
    </Container>
  )
}
