export const CALENDAR_SOCIAL_PRESS_CONSTANTS = [
  `“Sync all of the family’s calendars into one easy-to-use touchscreen device”`,
  `“An all-in-one planning tool for the whole family”`,
  `“Products that *finally* helped my family get organized”`,
  `“Perfect for the mom who’s juggling several different schedules”`,
]

export const FRAME_PRESS_CONSTANTS = [
  `“Genius… that's a great gift”`,
  `“It's a great way for grandparents to view all the latest grandkid exploits.”`,
  `“The Skylight Frame is one of the more attractive photo frames we've tested.”`,
  `“A digital photo frame you'll actually use.”`,
]
